.card--video.card {
  height: $video-card-height;
  width: $video-card-width;

  .card__inner {
    height: $video-card-thumb-height;

    &__thumb {
      top: $video-card-offset-y;
      right: $video-card-offset-x;
      left: $video-card-offset-x;
      bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .image-wrapper {
        top: $video-card-offset-y * -1;
        left: $video-card-offset-x * -1;
        width: $video-card-width;
        height: $video-card-thumb-height;
      }

      > video {
        width: $video-card-width;
        height: $video-card-thumb-height;
      }
    }

    &__details {
      top: $video-card-thumb-height;
      bottom: auto;
      padding: s(15px) s(40px);

      &::before {
        top: s(-2px);
        right: $video-card-offset-x;
        bottom: 0;
        left: $video-card-offset-x;
        border-radius: 0 0 $card-border-radius $card-border-radius;
      }

      h4 {
        color: $rb-grey-200;
      }

      > .card-progress-bar {
        top: s(-8px);
        left: $video-card-offset-x;
        right: $video-card-offset-x;
      }
    }
  }

  .card-panel {
    bottom: s(228px);
    right: $video-card-offset-x + s(16px);
    transition: $card-transition;
    // will-change: right;
  }

  .fav-icon {
    top: $video-card-offset-y + s(16px);
    right: $video-card-offset-x + s(16px);
  }

  &.is-focused {
    .card__inner {
      &__thumb {
        top: 0;
        right: 0;
        left: 0;

        .image-wrapper {
          top: 0;
          right: 0;
          left: 0;
        }
      }

      &__details {
        padding-top: s(30px);
        padding-bottom: s(35px);
        bottom: auto;

        &::before {
          right: 0;
          left: 0;
        }

        > .card-progress-bar {
          right: 0;
          left: 0;
        }
      }
    }

    .card-panel {
      right: s(16px);
    }

    .fav-icon {
      top: s(16px);
      right: s(16px);
    }
  }

  &.low-res-grid {
    margin-bottom: s(40px);
  }
}

/* DEBUG CARD SIZING AND SPACING */
// * {
//   &,
//   &::before,
//   &::after {
//     outline: 1px dashed #ff49d2;
//   }

//   @function _c($a, $b: 'px', $r: '') {
//     $i: str-index($a, $b);

//     @if $i {
//       @return str-slice($a, 1, $i - 1) + $r + _c(str-slice($a, $i + str-length($b)), $b, $r);
//     }

//     @return $a;
//   }

//   @function _r($n, $s) {
//     $x: floor($n * $s);

//     @if $s == 1.5 {
//       @if $x == 183 {
//         $x: 182;
//       }

//       @if $x == 273 {
//         $x: 272;
//       }

//       @if $x == 345 {
//         $x: 344;
//       }

//       @if $x == 981 {
//         $x: 980;
//       }
//     }

//     @return _c('#{$x}');
//   }

//   @function x($w, $h, $s: 1.5) {
//     @return '#{_r($w, $s)}×#{_r($h, $s)}';
//   }

//   .card {
//     &::after {
//       display: none;
//       content: x($card-width, $card-height);
//       position: absolute;
//       top: s(32px);
//       left: s(32px);
//       padding: 0 .35em;
//       font-size: s(19px);
//       font-weight: 600;
//       line-height: s(26px);
//       border-radius: s(2px);
//       background-color: #d9ff00;
//       color: #353c49;
//     }

//     &--channel::after {
//       content: x($channel-card-width, $channel-card-height);
//     }

//     &--channel--linear::after {
//       content: x($linear-channel-card-width, $linear-channel-card-height);
//     }

//     &--featured::after {
//       content: x($featured-card-width, $featured-card-height);
//     }

//     &--featured-small::after {
//       content: x($featured-card-small-width, $featured-card-small-height);
//     }

//     &--live::after {
//       content: x($live-card-width, $live-card-height);
//     }

//     &--video::after {
//       content: x($video-card-width, $video-card-height);
//     }
//   }
// }

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
  z-index: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

#home {
  position: absolute;
  top: 0;
  left: 0;
  transition: top 250ms ease-in-out;
  // will-change: top;
  z-index: 1;

  .overlay {
    top: s(-40px); // mostly for resume dialog
  }

  &.hasVideoOverlay {
    .video_overlay {
      opacity: 0.7;

      &--solid {
        opacity: 1;
      }
    }
  }

  &.isContentSectionFocused {
    .video_overlay {
      transition: opacity 200ms;
    }
  }

  .video_overlay {
    background-color: $pure-black;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: -100;
    transition: opacity 200ms 300ms;
    // will-change: opacity;
  }

  &.pinned {
    top: 0;
  }

  #homeContent {
    position: absolute;
    top: $main-content-top-offset;
    left: $main-content-left-offset;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
  }

  .menu {
    z-index: 10;
  }

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    width: s(1920px);
    height: s(1080px);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), $rb-blue-charcoal);
    background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0), $rb-blue-charcoal);
    content: ' ';
  }

  &.has10ftHeader {
    &:before {
      display: none;
    }
  }

  &.hide {
    &:before {
      background: none;
    }
  }

  .homeContextualModuleWrapper {
    position: relative;
    height: s(535px);
    top: 0;
    transition: top 200ms;
    // will-change: top;

    .homeContextualModule-events {
      position: absolute;
      left: s(1290px);
      top: 0;
    }
  }

  .homeContextualModule {
    margin-top: s(60px);
    margin-left: s(16px);
    width: $homeContextualModule-width;
    transition: opacity 200ms;
    // will-change: opacity;

    .homeContextualModule-heading {
      text-transform: uppercase;
      font-size: s(22px);
      color: $homeContextualModule-color;
      line-height: s(32px);
    }

    .homeContextualModule-title {
      font-size: s(68px);
      line-height: s(96px);
      color: $homeContextualModule-color;
      font-weight: 700;
    }

    .homeContextualModule-description {
      font-size: s(30px);
      min-height: s(144px);
      line-height: s(47px);
      color: $homeContextualModule-color;
      margin-bottom: s(32px);
      width: s(960px);
      text-align: justify;
    }
  }

  .pulse {
    display: inline-block;
    position: relative;
    top: s(-4px);
    margin-right: s(8px);
    width: s(12px);
    height: s(12px);
    border-radius: s(6px);
    background-color: $rb-red;
    vertical-align: baseline;

    &::after {
      content: '';
      position: absolute;
      top: s(-6px);
      left: s(-6px);
      width: s(24px);
      height: s(24px);
      border-radius: s(12px);
      background-color: $rb-red;
      animation: pulse 1800ms infinite;
      opacity: 0;
      // will-change: transform, opacity;
    }
    // will-change: box-shadow;
  }

  &.isContentSectionFocused {
    .pulse {
      &::after {
        animation: none;
      }
    }
  }

  &.isContextualModule {
    &.has10ftHeader {
      .list-panel {
        & .card-row:first-child .card-row-header {
          opacity: 0;
          transition: opacity 250ms;
          // will-change: opacity;
        }
      }
    }

    &.isContentSectionFocused {
      &.hasHomeContextualModule {
        top: s(-710px);

        .homeContextualModuleWrapper {
          // transform: translateY(s(-535px));
          top: s(-535px);
        }
      }

      &.has10ftHeader {
        top: s(-1080px);

        .list-panel {
          & .card-row:first-child .card-row-header {
            opacity: 1;
          }
        }
      }

      .home-featured-carousel-module {
        margin-bottom: s(80px);
      }
    }

    &.mainMenuCollapsed {
      #homeContent {
        top: $main-content-top-offset;
      }
    }
  }

  &.mainMenuCollapsed {
    #homeContent {
      top: 0;
    }

    .homeContextualModuleWrapper {
      // transform: translateY(s(-80px));
      top: s(-80px);
    }

    #settings {
      margin-top: s(80px);
    }

    .grid-page {
      margin-top: s(80px);
    }

    .home-featured-carousel-module {
      &__detail {
        transform: translateY(s(50px));
      }
    }
  }

  &.whiteTheme {
    &:before {
      position: absolute;
      top: s(-40px);
      left: 0;
      width: s(2000px);
      height: s(1130px);
      background-image: linear-gradient(180deg, transparent, $rb-blue-charcoal);
      background-image: -webkit-linear-gradient(270deg, transparent, $rb-blue-charcoal);
      background: $pure-white;
      content: ' ';
    }

    .card-row-header-title {
      color: $servus-black;
    }

    .progress-bar__indicator {
      background-color: $scrollbar-indicator;
    }

    .progress-bar__text {
      color: $rb-black;
    }
  }

  .home-featured-carousel-module {
    position: relative;
    height: s(870px);
    width: s(1920px) - $main-content-left-offset;
    transition: margin-bottom 200ms;
    // will-change: margin-bottom;

    &.is-focused {
      margin-bottom: s(-120px);
    }

    &__background {
      position: absolute;
      top: s(-144px);
      left: -$main-content-left-offset;
      width: s(1920px);
      height: s(1080px);
      transition: background-color 200ms;
      // will-change: background-color;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($pure-black, 0.3);
        background: linear-gradient(0deg, rgba($pure-black, 0.3), rgba($pure-black, 0.3)),
          linear-gradient(180deg, rgba(0, 15, 30, 0.05) 0.29%, rgba(0, 15, 30, 0.9) 100%);
        background: -webkit-linear-gradient(90deg, rgba($pure-black, 0.3), rgba($pure-black, 0.3)),
          -webkit-linear-gradient(270deg, rgba(0, 15, 30, 0.05) 0.29%, rgba(0, 15, 30, 0.9) 100%);
      }

      &.transparent-mode {
        background-color: transparent;
      }

      &.poster-loaded {
        img {
          opacity: 1;
          transition: opacity 200ms;
        }
      }

      &.poster-loaded.has-preview {
        img {
          transition-duration: 800ms;
        }
      }

      &.preview-loaded {
        video {
          opacity: 1;
          transition: opacity 100ms;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // object-fit: cover;
        opacity: 0;
        // will-change: opacity;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        // will-change: opacity;
      }

      &__preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__detail {
      $detail: '.home-featured-carousel-module__detail';
      height: s(495px);
      margin-bottom: s(100px);
      padding-right: s(100px);
      color: $rb-alabaster;
      opacity: 0;
      transform: translateY(s(80px));
      transition: transform 200ms;
      // will-change: transform, opacity;

      &::before,
      &::after {
        content: '';
        display: table;
        width: 100%;
      }

      &::after {
        clear: both;
      }

      &.in-view {
        opacity: 1;
        transition: transform 200ms, opacity 200ms;

        &.has-sidebar {
          #{$detail}__side {
            opacity: 1;
            transition: opacity 200ms;
          }
        }
      }

      &.has-thumbnail {
        #{$detail}__main {
          padding-left: s(173px);

          &__image {
            position: absolute;
            top: 0;
            left: 0;

            img {
              display: block;
              height: s(200px);
              width: s(133px);
              border-radius: s(8px);
              // object-fit: cover;
            }
          }

          &__title {
            h1 {
              width: s(735px);
              padding-top: 0;
              margin-top: s(-10px);
            }

            p {
              width: s(735px);
            }
          }
        }
      }

      &.has-title-treatment {
        #{$detail}__main {
          &__image {
            position: relative;
            height: s(180px);
            width: s(700px);
            margin-top: s(-30px);
            margin-bottom: s(-68px);

            img {
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              max-height: 100%;
              max-width: 100%;
              // object-fit: contain;
            }
          }
        }
      }

      &.artwork-loaded {
        #{$detail}__main {
          &__image {
            img {
              opacity: 1;
              transition: opacity 200ms;
            }
          }
        }

        &.has-title-treatment {
          #{$detail}__main {
            &__title {
              h1 {
                width: 100%;
                opacity: 0;
              }
            }
          }
        }
      }

      &__main {
        position: relative;
        width: s(910px);
        float: left;

        &__image {
          img {
            opacity: 0;
            // will-change: opacity;
          }
        }

        &__title {
          h1 {
            width: s(736px);
            margin: 0;
            font-size: s(60px);
            font-weight: 600;
            line-height: s(60px);
            padding: s(18px) 0;
            word-wrap: break-word;
            // will-change: opacity;
          }

          h3 {
            @extend .ellipsis;
            margin: 0 0 s(6px);
            font-size: s(40px);
            font-weight: 600;
            line-height: s(44px);
          }

          p {
            display: block;
            margin: 0;
            font-weight: 400;
            font-size: s(28px);
            line-height: s(42px);
            width: s(910px);
          }
        }
      }

      &__side {
        position: relative;
        width: s(580px);
        float: right;
        text-align: right;
        padding: 0 s(24px) s(8px) 0;
        border-right: s(4px) solid $rb-red;
        opacity: 0;
        // will-change: opacity;

        h2 {
          margin: 0;
          font-size: s(40px);
          font-weight: 600;
          line-height: s(47px);
          padding: s(6px) 0;
          text-transform: capitalize;
        }

        h6 {
          @extend .ellipsis;
          margin: 0;
          font-size: s(24px);
          font-weight: 600;
          line-height: s(36px);
          text-transform: uppercase;
        }

        p {
          margin: 0;
          font-size: s(28px);
          font-weight: 400;
          line-height: s(42px);
        }

        pre {
          display: none;
          @extend .ellipsis;
          position: absolute;
          right: s(-4px);
          top: 110%;
          margin: 0;
          font-family: inherit;
          font-size: s(18px);
          font-weight: 400;
          line-height: s(30px);
          background-color: rgb(179, 255, 0);
          color: $rb-black;
          padding: s(2px) s(12px);
          border-radius: s(4px);
          text-transform: uppercase;
        }
      }
    }

    .card-row {
      z-index: 2;
    }
  }

  @at-root .is-servus & {
    .homeContextualModule {
      .homeContextualModule-heading {
        font-weight: bold;
      }

      .homeContextualModule-title {
        font-size: s(60px);
      }

      .homeContextualModule-description {
        font-size: s(28px);
        text-align: initial;
      }
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    opacity: 0.3;
    transform: scale(1);
  }
}

#settings {
  position: absolute;
  top: 0;
  left: 0;
  width: s(2000px);
  margin: auto;
  font-size: $menu-title;
  color: $settings-color;

  .is-hidden {
    visibility: hidden;
  }

  .title {
    position: relative;

    h2 {
      font-size: s(40px);
      font-weight: 600;
      color: $settings-color;
      left: s(16px);
      margin: s(40px) 0;
      position: relative;
      white-space: nowrap;
    }
  }

  .settings__radioGroupHeader,
  .settings__optionsListHeader {
    font-weight: 600;
  }

  .regular-button {
    &::before {
      background-color: transparent;
    }

    .regular-button__inner {
      color: $settings-button-color;
      text-align: left;
      &--logoutBtn {
        color: $rb-alabaster;
      }
    }

    &.is-focused {
      &::before {
        background-color: $settings-button-background;
      }

      &.regular-button--logoutBtn {
        &::before {
          background-color: $rb-red;
        }
      }

      .regular-button__inner {
        color: $settings-button-color--focused;
        &--logoutBtn {
          color: $rb-alabaster;
        }
      }
    }

    &.is-block {
      margin: s(16px) 0;

      &::before {
        background-color: $servus-black;
      }

      .regular-button__inner {
        text-align: center;
        color: $rb-alabaster !important;
      }

      &.is-focused {
        &::before {
          background-color: $servus-red;
        }
      }
    }
  }

  .versionNumber {
    margin-top: s(35px);
    margin-left: s(5px);
    color: $rb-white;
  }

  @at-root .is-servus & {
    .title {
      height: auto;

      h2 {
        position: static;
      }
    }

    #settingsPanel {
      h2 {
        font-size: s(48px);
        line-height: s(72px);
        margin: s(48px) 0;
        left: 0;
        text-transform: uppercase;
      }
    }

    .versionNumber {
      color: #1a1919;
      font-size: s(20px);
    }

    .regular-button {
      .regular-button__inner {
        text-align: left;
        color: #747477;
      }

      .regular-button__value {
        font-family: 'RedBull';
      }
    }

    .captions-settings-helptext {
      color: $rb-grey-200;
    }
  }
}

#settingsPanel {
  height: 100%;
  width: s(664px);
  color: $rb-white;
  left: 0;
}

#focusPanel {
  height: 100%;
  position: absolute;
  top: 0;
  left: s(1120px);
  width: 20%;
  margin-top: s(175px);
  font-weight: normal;
}

.accountPanel {
  .loginMsg {
    width: s(800px);
    margin: s(160px) 0 s(100px) 0;

    .loginMsg--title {
      font-size: s(68px);
      line-height: s(96px);
      color: $settings-color;
      font-weight: 700;
    }

    .loginMsg--msg {
      font-size: s(30px);
      min-height: s(144px);
      line-height: s(47px);
      color: $settings-color;
      margin-bottom: s(32px);
      text-align: justify;
    }
  }

  .loginCodes {
    width: s(720px);
    position: absolute;
    left: s(916px);
    top: s(100px);
    display: inline-block;
    color: $settings-color;

    img {
      width: s(280px);
      height: s(280px);
      border-radius: s(15px);
      background-color: $pure-white;
      opacity: 0;
      transition: opacity 100ms;
      // will-change: opacity;

      &.loaded {
        opacity: 1;
      }
    }

    h4 {
      font-weight: 100;
      font-size: s(26px);
      opacity: 0.5;
      margin: s(32px) 0 s(8px) 0;
    }

    h3 {
      font-weight: 600;
      font-size: s(48px);
      margin: s(8px) 0;
    }

    p {
      font-weight: normal;
      font-size: s(26px);
      margin: s(8px) 0;
      line-height: s(40px);
      opacity: 0.5;
    }

    .loginCodes_TOKEN {
      font-weight: 600;
      font-size: s(60px);
      margin: s(8px) 0;
      letter-spacing: s(20px);
      line-height: s(72px);
    }
  }

  .page-buttons {
    margin: s(64px) 0 s(32px) s(16px);
  }

  .empty-message {
    margin-left: s(16px);
    max-width: s(1600px);
  }

  @at-root .is-servus & {
    .loginCodes {
      top: s(80px);
      left: s(900px);

      h4,
      p {
        font-weight: normal;
        font-size: s(30px);
        line-height: s(45px);
        margin: s(24px) 0;
        color: $servus-black;
        opacity: 1;
      }

      h3 {
        font-size: s(40px);
        line-height: s(60px);
        font-weight: bold;
        margin: 0;
      }

      .loginCodes_TOKEN {
        font-weight: bold;
        font-size: s(60px);
        line-height: s(90px);
        margin: 0;
      }

      img {
        width: s(304px);
        height: s(304px);
        transform: translateX(s(-25px));
      }
    }

    .loginMsg {
      width: s(758px);
      margin: s(218px) 0 0 0;

      .loginMsg--title {
        font-size: s(60px);
        line-height: s(90px);
        margin: 0 0 s(16px);
      }

      .loginMsg--msg {
        font-weight: normal;
        font-size: s(30px);
        line-height: s(45px);
        text-align: left;
        min-height: s(225px);
        margin-bottom: s(30px);
      }
    }

    .card-row-header {
      height: auto;
      &-title {
        position: static;
        font-size: s(48px);
        line-height: s(72px);
        margin: s(48px) 0;
        width: s(241px);
        left: 0;
        text-transform: uppercase;
      }
    }

    .progress-bar {
      top: s(18px);
    }
  }
}

.settingsTextPageWrapper {
  .text-page__content {
    h1 {
      font-size: 1.5em;
      line-height: 1.5em;
    }

    h2 {
      font-weight: 600;
      line-height: 1.5em;
    }
  }

  @at-root .is-servus & {
    .text-page {
      > .text-page__content {
        &.is-focused {
          &::-webkit-scrollbar-thumb {
            background: $rb-black;
          }
        }
      }

      ::-webkit-scrollbar-track {
        background-color: $rb-grey-350;
      }

      ::-webkit-scrollbar-thumb {
        background: darkgrey;
      }
    }
  }
}
